function HeaderCarousel(){

    TweenMax.killChildTweensOf( document.getElementById("header-carousel") );
    TweenMax.killAll(false, false, true);

    var self = this;

    console.log("home HeaderCarousel");

    self.$newIndex = 0;

    self.$previous = "#s3";
    self.$new = "#s0";

    self.animating = false;

    self.newDepth = 200;
    self.previousDepth =150;
    self.allDepth =100;

    self.timer ="";

    function starAutoPlay(){

        self.animating = true;

        self.$newIndex ++;

        if ( self.$newIndex === 4 ){

            self.$newIndex = 0;
        }

        var id = "#s" + self.$newIndex;

        self.$previous = self.$new;

        self.$new = id;

        TweenMax.set(self.$new, {opacity: 0});

        TweenMax.set(".slide", {css: {zIndex: self.allDepth}});
        TweenMax.set(self.$previous, {css: {zIndex: self.previousDepth}});
        TweenMax.set(self.$new, {css: {zIndex: self.newDepth}});

        TweenMax.to(self.$new, 0.5, {opacity: 1, onComplete:animationComplete});

        var xPos = self.$newIndex * 25;
        var perc = xPos + "%";

        TweenMax.to($(".slide-marker"), 0.3, {left:perc});

    }

    function animationComplete(){

        TweenLite.delayedCall(3, starAutoPlay);

        //self.timer = setTimeout(starAutoPlay, 3000);

        self.animating = false;
    }

    //self.timer = setTimeout(starAutoPlay, 3000);

    TweenLite.delayedCall(3, starAutoPlay);

    $( ".navigation-button" ).each(function( index ) {

        $(this).click(function (){

            if (!self.animating) {

                //clearTimeout(self.timer);

                TweenMax.killAll(false, false, true);

                var id = "#s" + index;

                self.animating = true;

                if (id !== self.$new) {

                    self.$newIndex =index;

                    self.$previous = self.$new;

                    self.$new = id;
                    console.log(self.$new);

                    TweenMax.set(self.$new, {opacity: 0});

                    TweenMax.set(".slide", {css: {zIndex: self.allDepth}});
                    TweenMax.set(self.$previous, {css: {zIndex: self.previousDepth}});
                    TweenMax.set(self.$new, {css: {zIndex: self.newDepth}});

                    TweenMax.to(self.$new, 0.5, {opacity: 1, onComplete: animationComplete});

                    var xPos = index * 25;
                    var perc = xPos + "%";

                    TweenMax.to($(".slide-marker"), 0.3, {left: perc});
                } else {

                    self.animating = false;
                }

            }

        });

    });

}
