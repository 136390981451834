function ContactMap(container){

    console.log("---------------------------- ContactMap");

    console.log("---------------------------- container ", container);

    var $mapWrapper;
    var mapWrapperID;

    var $map;
    var mapID;

     console.log("map ID "+ mapWrapperID);


    if (container === undefined){

        console.log("undefined");

        $mapWrapper = $(".map-wrapper");
        mapWrapperID = $(".map-wrapper").attr("ID");

        $map = $(".contact-map");
        mapID = $(".contact-map").attr("ID");

     } else {

        console.log("not undefined");

        $mapWrapper = $(".map-wrapper", container);
        mapWrapperID = $(".map-wrapper", container).attr("ID");

        $map = $(".contact-map", container);
        mapID = $(".contact-map", container).attr("ID");
    }


    function initContactMap() {

        var contactMapOptions;
        var contactPinPoints;
        var contactMapPoints;
        var contactZoom = parseFloat($("#" + mapWrapperID).attr("data-zoom"));

        var pinLat = parseFloat($("#" + mapWrapperID).attr("data-lat"));
        var pinLon = parseFloat($("#" + mapWrapperID).attr("data-lon"));

        var contactLatCentre = parseFloat($("#" + mapWrapperID).attr("data-centre-lat"));
        var contactLonCentre = parseFloat($("#" + mapWrapperID).attr("data-centre-lon"));

        console.log("contactZoom "+ contactZoom);

        contactPinPoints = new google.maps.LatLng(pinLat,pinLon);

        contactMapPoints = new google.maps.LatLng(contactLatCentre,contactLonCentre);

       // var lat = $("#contact-map-wrapper").attr("data-lat");
       // var lon = $("#contact-map-wrapper").attr("data-lat");

        contactMapOptions = {
            zoom: contactZoom,
            center: contactMapPoints,
            disableDefaultUI: true,
            panControl: false,
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL,
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            },
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'blue']
            },
            scaleControl: false,
            //draggable: false,
            scrollwheel: false,


        };

       var contactMap = new google.maps.Map(document.getElementById(mapID), contactMapOptions);

        var marker = new google.maps.Marker({
            position: contactPinPoints,
            map: contactMap,

        });


    }

    function runContactAPITimercheck(){

        console.log("runAPITimercheck");

        console.log(data.apiLoaded);

        if (data.apiLoaded){

            console.log("run map");

            initContactMap();

            //run map
        } else {

            console.log("check map API again");

            setTimeout(runContactAPITimercheck, 500);
        }

    }

    runContactAPITimercheck();

}
