/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

//var WebFont = require('webfontloader');

var $ = jQuery;

var post = {};


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

          console.log("window.gwrf");
          
          console.log(window.gwrf);

          var tabClicked = false;
          var left = 0;

          Barba.Pjax.start();

          var size = new BrowserSize();

          size.getWindowSize();

          $( window ).resize(function() {

              size.getWindowSize();

          });

          Barba.Dispatcher.on('linkClicked', function(element, event) {

              console.log("linked clicked ", element, event);

              console.log("parent ", element.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode);

              if ($(element.parentNode).hasClass("tab-link")){

                  console.log("this is menu item tab link");

                  tabClicked = true;

              //} else if (element.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.className
                  // === 'tab-navigation'){
              } else if ($(element.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode).hasClass("tab-navigation")){

                  console.log("this is tab item");

                  left = $(element.parentNode.parentNode).scrollLeft();

                  console.log("------------------- scrollLeft " + left);

                  tabClicked = true;

              }  else {

                  console.log("this is not tab item");

                  tabClicked = false;
              }
              /*console.log("linked clicked ", element, event);

              console.log("parent ", element.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode);

              if(element.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.className === 'tab-navigation'){

                  console.log("this is tab item");

                  left = $(element.parentNode.parentNode).scrollLeft();

                  console.log("scrollLeft " + left);

                  tabClicked = true;

              } else {

                  console.log("this is not a tab item");

                  tabClicked = false;
              }*/

          });

          Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;

          Barba.Pjax.preventCheck = function(evt, element) {

              if (!Barba.Pjax.originalPreventCheck(evt, element)) {
                  return false;
              }

              // No need to check for element.href -
              // originalPreventCheck does this for us! (and more!)

              console.log("e " +element);

              if (/.pdf/.test(element.href.toLowerCase())) {
                  return false;
              }
              if (element.className === 'ab-item') {

                  console.log("prevent click on admin item");

                  return false;
              }
              if (element.className === 'ab-submenu') {

                  console.log("prevent click on admin item");

                  return false;
              }

              return true;
          };

          var menu = new Menu();

          var tabMenu = new TabMenu();

         //  var galleries = new Galleries();

          var FadeTransition = Barba.BaseTransition.extend({
              start: function() {
                  /**
                   * This function is automatically called as soon the Transition starts
                   * this.newContainerLoading is a Promise for the loading of the new container
                   * (Barba.js also comes with an handy Promise polyfill!)
                   */

                  // As soon the loading is finished and the old page is faded out, let's fade the new page
                  Promise
                      .all([this.newContainerLoading, this.fadeOut()])
                      .then(this.fadeIn.bind(this));
              },

              fadeOut: function() {
                  /**
                   * this.oldContainer is the HTMLElement of the old Container
                   */



                  return $(this.oldContainer).animate({ opacity: 0 }).promise();
              },

              fadeIn: function() {
                  /**
                   * this.newContainer is the HTMLElement of the new Container
                   * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
                   * Please note, newContainer is available just after newContainerLoading is resolved!
                   */


                  var _this = this;
                  var $el = $(this.newContainer);

                  /* ------------ my logic -----------*/

                  if( $('#facilites-map', this.newContainer).length )         // use this if you are using id to check
                  {
                      // it exists
                      var faclities = new Facilities();
                  }

                  if( $('#amenities-map', this.newContainer).length )         // use this if you are using id to check
                  {

                      var map = new Map();
                  }
                  if($('.contact-form', this.newContainer).length)     // use this if you are using id to check
                  {
                      console.log("contact page");
                      var contactMap = new ContactMap(this.newContainer);
                  }

                  if($('.gform_wrapper', this.newContainer).length)
                  {


                      console.log("has form!!!");
                      console.log(" gform_post_render 1");

                      $(document).trigger('gform_post_render', [1, 0]);

                  }


                  if( $('#home-videos', this.newContainer).length )         // use this if you are using id to check
                  {
                      // it exists
                      var videos  = new Videos();
                  }

                  if( $('#header-carousel', this.newContainer).length )         // use this if you are using id to check
                  {
                      // it exists
                      var carousel  = new HeaderCarousel();
                  }

                  if( $("#home-featured", this.newContainer).length) {

                     // var featuredPanels  = new FeaturedPanels();


                  }
                  if( $('#home-departments', this.newContainer).length )         // use this if you are using id to check
                  {
                      // it exists

                      var departments = new DepartmentsSwiper();

                  }


                  if($(".page-links", this.newContainer).length){

                      var scroll = new ScrollLinks();

                  }

                  if( $(".master-gallery", this.newContainer).length)
                  {

                      console.log("----------- master gallery");

                      var galleries = new Galleries();

                  }

                  if( $("#tabs", this.newContainer).length) {

                      //document.getElementById("#tabs").scrollIntoView();

                      console.log("called tabs");

                      var tabMenu = new TabMenu(this.newContainer);

                  }

                  var menu = new Menu();


                  if (tabClicked ===true){

                      console.log("scroll to tab");

                      var target = $("#tabs", this.newContainer);

                      //var target = "#tabs";
                      var p = $(".tab-navigation").position();

                      console.log("---------------------- p ", p.top);

                      console.log("---------------------- size.winW ", size);

                       if (size.winW > 1199){

                           //console.log("----------------------  greater than 1199");

                           target = p.top;

                       } else {

                           console.log("----------------------  less than 1199");

                           target = p.top - 60;
                       }

                      TweenMax.to(window, 1, {scrollTo: {y: target}, ease:Power2.easeInOut});

                      var selector = $(".tab-navigation ul");


                      console.log("---------------------- left "+ left);

                      TweenMax.set(selector, {scrollTo: {x: left }});


                  } else {

                      TweenMax.to(window, 0, {scrollTo: {y: 0}, ease:Power2.easeInOut});
                  }

                  /* ------------ my logic -----------*/


                  $(this.oldContainer).hide();

                  $el.css({
                      visibility : 'visible',
                      opacity : 0
                  });



                  $el.animate({ opacity: 1 }, 400, function() {
                      /**
                       * Do not forget to call .done() as soon your transition is finished!
                       * .done() will automatically remove from the DOM the old Container
                       */

                      if (tabClicked ===true){


                      }

                      _this.done();

                      gtag('config', 'UA-122400515-1', {'page_path': location.pathname});


                      if( $(".post-gallery", this.newContainer).length)
                      {

                          console.log("----------- post gallery");

                          post.gallery = new Gallery();

                      }
                  });
              }
          });

          /**
           * Next step, you have to tell Barba to use the new Transition
           */

          Barba.Pjax.getTransition = function() {
              /**
               * Here you can use your own logic!
               * For example you can use different Transition based on the current page or link...
               */

              return FadeTransition;
          };




        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

      'single_post'  : {
          init: function () {

              post.gallery = new Gallery();
          }
      },
      'single_casestudy'  : {
          init: function () {

              console.log("----------- single casestudy");

              var galleries = new Galleries();
          }
      },
    // Home page
    'home': {
      init: function() {

         /* var sponsors = new Sponsors();*/

         console.log("home");

          var carousel  = new HeaderCarousel();

          var videos  = new Videos();

          var departments = new DepartmentsSwiper();

          var statistics  = new Statistics();

         // var featuredPanels  = new FeaturedPanels();


        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
     'page_template_template_contact':{

         init: function() {

            console.log("contact page");

             if($('.contact-form').length)     // use this if you are using id to check
             {
                 console.log("contact page");
                 var contactMap = new ContactMap();
             }

             // var galleries = new Galleries();
         }
     },
      'page_template_template_previous_event': {
          init: function() {

             // var galleries = new Galleries();
          }
      },
      'page_template_template_current_event': {
          init: function() {

              //var galleries = new ProgrammeGalleries();
          }
      },
      'page_template_template_facilities': {
          init: function() {

              var faclities = new Facilities();
          }
      },
      'page_template_template_amenities':{

          init: function() {

              //window.initMap();
              if($('#amenities-map').length)     // use this if you are using id to check
              {

                 var map = new Map();
              }
              var scroll = new ScrollLinks();
          }
      }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
